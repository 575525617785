import React, { useState, useEffect, useRef } from 'react';
import UCILogo from '../assets/UCI.svg.png';

export default function Education() {
  const isSmallScreen = window.innerWidth <= 768;

  return (
    <div style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <div style={{zIndex: 10, position: 'relative'}}>
        <div style={{textAlign: 'center', fontSize: isSmallScreen ? '35px' : '55px', 
            color: '#333333', marginBottom: '10px',
            }}>Education
        </div>
        <a 
        href="https://catalogue.uci.edu/donaldbrenschoolofinformationandcomputersciences/softwareengineering_bs/#text" 
        target="_blank" 
        rel="noreferrer" 
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        >
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: isSmallScreen ? '5px' : '40px'}}>

                <img src={UCILogo} style={{width: 'auto', height: isSmallScreen ? '180px' : '200px'}}/>
                { !isSmallScreen && (
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{color: '#333333', fontSize: isSmallScreen ? '18px' : '30px'}}>
                        University Of California, Irvine
                    </div>
                    
                    <div style={{color: '#333333', fontSize: isSmallScreen ? '18px' : '30px'}}>
                        Software Engineering, B.S.
                    </div>

                    <div style={{color: '#333333', fontSize: isSmallScreen ? '18px' : '30px'}}>
                        Graduating In March 2026
                    </div>
                    </div>
                )}
            </div>
        </a>

        {isSmallScreen && (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', 
            marginTop: '10px', marginBottom: '50px'}}>
            <div style={{color: '#333333', fontSize: isSmallScreen ? '18px' : '30px'}}>
                University Of California, Irvine
            </div>
            <div style={{color: '#333333', fontSize: isSmallScreen ? '18px' : '30px'}}>
                Software Engineering, B.S.
            </div>
            </div>
        )}
      </div>
    </div>
  );
}