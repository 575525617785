import React, { useState, useEffect, useRef } from 'react';
import MyFyeIcon from '../assets/myFyeLogo.png';
import FinFriendIcon from '../assets/FinFriendIcon.png';
import buci from '../assets/buci.jpg';
import whiteTriangle from '../assets/whiteTriangle.png';
import whiteX from '../assets/whiteX.png';
import grayX from '../assets/grayX.png';
import grayTriangle from '../assets/grayTriangle.png';

interface CompanySelectorProps {
  changingScrollPosition: boolean;
}

const CompanySelector: React.FC<CompanySelectorProps> = ({ changingScrollPosition }) => {

  const refOne = useRef<HTMLDivElement>(null);
  const refTwo = useRef<HTMLDivElement>(null);
  const refThree = useRef<HTMLDivElement>(null);
  const refFour = useRef<HTMLDivElement>(null);
  const isSmallScreen = window.innerWidth <= 768;
  const isReallyBigScreen = window.innerWidth >= 1100;

  type BlurbKey = 'one' | 'two' | 'three' | 'four';

  const [blurbHeight, setBlurbHeight] = useState({
    one: 0,
    two: 0,
    three: 0,
    four: 0,
  });


const toggleBlurb = (blurb: BlurbKey) => {
    // Toggle the clicked blurb and set all others to 0 (hidden)
    const updatedHeights = {
      one: 0,
      two: 0,
      three: 0,
      four: 0,
    };

    const refMap = {
      one: refOne,
      two: refTwo,
      three: refThree,
      four: refFour,
    };

    const refCurrent = refMap[blurb]?.current;
    const maxHeight = 240;
    if (refCurrent && blurbHeight[blurb] === 0) {
      if (isSmallScreen) {
      updatedHeights[blurb] = refCurrent.scrollHeight;
      } else {
      updatedHeights[blurb] = Math.min(refCurrent.scrollHeight, maxHeight);
      }
    }
    setBlurbHeight(updatedHeights);
  };
  

  useEffect(() => {

    const updatedHeights = {
      one: 0,
      two: 0,
      three: 0,
      four: 0,
    };

    if (changingScrollPosition) {
      setBlurbHeight(updatedHeights);
    }
}, [changingScrollPosition]);

  return (
    <div>
      <div style={{display: 'flex', flexDirection: 'column', 
      width: isSmallScreen ? '100vw': '90vw',
      justifyContent: 'center',
      gap: isSmallScreen ? '10px' : '20px', color:'#333333', 
      fontSize: isSmallScreen ? '18px' : '20px', 
      marginLeft: isSmallScreen ? '0px' : '0px', marginTop: '5px',
      zIndex: 10,
      position: 'relative'}}>
        
        <hr style={{ border: 'none', borderTop: '1.5px solid #666666', margin: '0px 0' }} />

        <div onClick={() => toggleBlurb('one')} style={{display: 'flex', 
          flexDirection: 'row', alignItems: 'center', gap: '30px', cursor: 'pointer'}}>
          <img src= {buci} alt="" style={{ marginLeft: isSmallScreen ? '10px' : '0px', 
            width: isSmallScreen ? '50px' : '50px', 
            borderRadius: '3px',
            boxShadow: '0px 4px 6px rgba(0, 0.1, 0.1, 0.5)'}}></img>

          <div style={{fontSize: isSmallScreen ? '18px' : '30px'}}>Technical Lead At Blockchain UCI</div>
          <img src={ blurbHeight.one > 0 ? grayX : grayTriangle} style={{
            width: isSmallScreen ? '25px' : '30px', 
            height: 'auto', 
            marginLeft: 'auto', 
            marginRight: isSmallScreen ? '40px' : '15vw'}}/>
        </div>

        <div ref={refOne} style={{overflow: 'hidden', transition: 'height 800ms ease', height: `${blurbHeight.one}px`}}>
          <div style={{fontSize: isSmallScreen ? '18px' : '20px', lineHeight: '1.5', paddingLeft: isSmallScreen ? '10px' : '0px',}}>
            <a href="https://www.blockchainuci.org/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ 
                  color: '#333333', 
                  cursor: 'pointer', 
                  fontSize: isSmallScreen ? '18px' : '20px',
                  textDecoration: 'underline', // Add underline explicitly
                  textDecorationColor: '#333333' // Set underline color to match text color
                }}>
              BlockchainUCI.org
              </div>
            </a>
            <br/>
            <div style={{marginTop: '-20px'}}>
                    As the most senior software engineer, I coordinated <span style={{fontWeight: 'bold'}}>30+</span> technical 
                    interviews with our busy schedules. We are currently working on
                    a decentralized rental market where transactions and collatoral are
                    handled on chain. 
                <br/>
            </div>
          </div>
        </div>

        <hr style={{ border: 'none', borderTop: '1.5px solid #666666', margin: '0px 0' }} />

        <div onClick={() => toggleBlurb('two')} style={{display: 'flex', 
          flexDirection: 'row', alignItems: 'center', gap: '30px', cursor: 'pointer'}}>
            <img
            src= {MyFyeIcon}
            alt=""
            style={{
              marginLeft: isSmallScreen ? '10px' : '0px',
              width: isSmallScreen ? '50px' : '50px',
              height: 'auto',
              borderRadius: '5px',
              boxShadow: '0px 4px 6px rgba(0, 0.1, 0.1, 0.5)'
            }}></img>
          <div style={{fontSize: isSmallScreen ? '18px' : '30px'}}>Co-Founder / Software Engineer At Myfye</div>
          <img src={ blurbHeight.two > 0 ? grayX : grayTriangle} style={{width: isSmallScreen ? '25px' : '30px', height: 'auto', marginLeft: 'auto', marginRight: isSmallScreen ? '40px' : '15vw'}}/>
        </div>

        <div ref={refTwo} style={{overflow: 'hidden', transition: 'height 800ms ease', height: `${blurbHeight.two}px`}}>


          <div style={{fontSize: isSmallScreen ? '18px' : '20px', lineHeight: '1.5', paddingLeft: isSmallScreen ? '10px' : '0px',}}>

            <div style={{display: 'flex', gap: '30px'}}>

            <a 
              href="https://myfye.com/" 
              target="_blank" 
              rel="noopener noreferrer" 
              style={{ color: 'inherit', textDecoration: 'none' }} // Remove default underline
            >
              <div 
                style={{ 
                  color: '#333333', 
                  cursor: 'pointer', 
                  fontSize: isSmallScreen ? '18px' : '20px',
                  textDecoration: 'underline', // Add underline explicitly
                  textDecorationColor: '#333333' // Set underline color to match text color
                }}
              >
                Myfye.com
              </div>
            </a>
            </div>

            <br/>
            <div style={{marginTop: isSmallScreen? '-35px' : '-25px'}}>
              I partnered with an industry expert to build a banking Dapp. 
              I built it with Solana and it can hold <span style={{fontWeight: 'bold'}}>US Government bonds on chain. </span>
               For the front-end, I used React with TypeScript, HTML, CSS and Figma. The most challenging part was
              the complex, 10+ minute long, algorithms for adapting to network congestion, swap failures and high fees.
            </div>
            </div>
        </div>
                

        <hr style={{ border: 'none', borderTop: '1.5px solid #666666', margin: '0px 0' }} />
        <div 
          onClick={() => toggleBlurb('three')}
          style={{display: 'flex', 
          flexDirection: 'row', alignItems: 'center', gap: '30px', cursor: 'pointer'
          }}>

            <img
            src= {FinFriendIcon}
            alt=""
            style={{
              marginLeft: isSmallScreen ? '10px' : '0px',
              width: isSmallScreen ? '50px' : '50px',
              borderRadius: '15px',
              height: 'auto',
              boxShadow: '0px 4px 6px rgba(0, 0.1, 0.1, 0.5)'
            }}></img>

            <div style={{fontSize: isSmallScreen ? '18px' : '30px'  }}>
              Founding Engineer At FinFriend
            </div>
            <img src={ blurbHeight.three > 0 ? grayX : grayTriangle} style={{width: isSmallScreen ? '25px' : '30px', height: 'auto', marginLeft: 'auto', marginRight: isSmallScreen ? '40px' : '15vw'}}/>
        </div>

        <div
          ref={refThree}
          style={{
          overflow: 'hidden',
          transition: 'height 800ms ease',
          height: `${blurbHeight.three}px`,
          }}>
          <div style={{fontSize: isSmallScreen ? '18px' : '20px', paddingLeft: isSmallScreen ? '10px' : '0px', lineHeight: '1.5'}}> 
            <div style={{display: 'flex', gap: '30px'}}>
              <a href="https://finfriend.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ 
                  color: '#333333', 
                  cursor: 'pointer', 
                  fontSize: isSmallScreen ? '18px' : '20px',
                  textDecoration: 'underline', // Add underline explicitly
                  textDecorationColor: '#333333' // Set underline color to match text color
                }}>
                FinFriend.com
              </div>
              </a>
            </div>
              <br/>
              <div style={{marginTop: isSmallScreen ? '-30px' : '-30px'}}>
                      I founded a create-your-own-currency platform for iOS, Android and web that has made over <span style={{fontWeight: 'bold'}}>$1,000 </span> in revenue.
                      The most challenging part was creating an exchange that has traded over <span style={{fontWeight: 'bold'}}>$5,000 </span> in volume.
                      Please click on the link and make a free trial currency.
                <br/>
              </div>
            </div>
        </div>

                {isSmallScreen && (
              <hr style={{ border: 'none', borderTop: '1.5px solid #666666', marginTop: '10px', width: '100vw' }} />
              )}
              { !isSmallScreen && (
            <hr style={{ border: 'none', borderTop: '1.5px solid #666666', margin: '0px 0' }} />
              )}
            </div>

    </div>



  );
}

export default CompanySelector;