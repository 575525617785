import React, { useState, useEffect } from 'react';


interface CompanySelectorProps {
  changingScrollPosition: boolean;
  scrollPosition: number;
  setScrollPosition: any;
}

const Navigation: React.FC<CompanySelectorProps> = ({ 
  changingScrollPosition, 
  scrollPosition, 
  setScrollPosition }) => {

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 700);
  
    const [option1Hovered, setOption1Hovered] = useState(false);
    const [option2Hovered, setOption2Hovered] = useState(false);
    const [option3Hovered, setOption3Hovered] = useState(false);
    const [option4Hovered, setOption4Hovered] = useState(false);

    const [nav1Class, setNav1Class] = useState('hidden');
    const [nav2Class, setNav2Class] = useState('hidden');
    const [nav3Class, setNav3Class] = useState('hidden');
    const [nav4Class, setNav4Class] = useState('hidden');
    const [nav5Class, setNav5Class] = useState('hidden');

    useEffect(() => {
      // Trigger the animations when the component mounts
      setTimeout(() => {
        setNav1Class('nav1');
        
        setNav2Class('nav2');
        setNav3Class('nav3');
        setNav4Class('nav4');
        setNav5Class('nav5');
      }, 100); 

      setTimeout(() => {
        setNav1Class('');
        setNav2Class('');
        setNav3Class('');
        setNav4Class('');
        setNav5Class('');
      }, 4800); 
    }, []);

    useEffect(() => {
      /* Update isSmallScreen state when the window is resized */
      const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 700);
      };
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    const handleClick = (number: number) => {
      console.log('Clicked number:', number);
      setScrollPosition(number)
    };

    
    return (

      <div>
        <div
          style={{
            color: '#333333',
            position: 'fixed',
            bottom: '0',
            left: '0',
            width: '100%',
            padding: '10px',
            textAlign: 'center',
            zIndex: 1000,
          }}
        >
          {!isSmallScreen && (
              <div style={{ display: 'flex', justifyContent: 'center',
                flexDirection: 'column',
              alignItems: 'center', width: '100vw', 
              gap: scrollPosition == 3 ? '40px' : '10px',
              transition: 'gap 0.5s ease-in-out',
              marginBottom: scrollPosition == 3 ? '15px' : '5px', }}>

            <div 


              style={{ 
                fontSize: '22px',  
                textAlign: 'center',
                opacity: scrollPosition < 2 ? 1 : 0, 
                height: scrollPosition < 2 ? '22px' : '0px', 
                transition: 'opacity 0.5s ease-in-out, height 0.5s ease-in-out',
                position: 'relative',
                cursor: 'pointer',
              }}
              onClick={() => handleClick(2)}
              className={`hover-effect ${nav1Class}`}
            >
              Skills
            </div>
            <div 
              style={{ 
                fontSize: '22px',  
                textAlign: 'center',
                opacity: scrollPosition < 3 ? 1 : 0, 
                height: scrollPosition < 3 ? '22px' : '0px', 
                transition: 'opacity 0.5s ease-in-out, height 0.5s ease-in-out',
                position: 'relative',
                cursor: 'pointer',
              }}
              onClick={() => handleClick(3)}
              className={`hover-effect ${nav2Class}`}
            >
              Education
            </div>
            <div 
              style={{ 
                fontSize: scrollPosition == 3 ? '30px' : '22px',
                textAlign: 'center',
                opacity: scrollPosition < 4 ? 1 : 0, 
                height: scrollPosition < 4 ? '22px' : '0px', 
                transition: 'opacity 0.5s ease-in-out, height 0.5s ease-in-out, font-size 0.5s ease-in-out',
                position: 'relative',
                cursor: 'pointer',
              }}
              onClick={() => handleClick(4)}
              className={`${nav3Class}`}
              onMouseEnter={() => setOption3Hovered(true)}
              onMouseLeave={() => setOption3Hovered(false)}
            >
                {option3Hovered ? (<div style={{textDecoration: 'underline'}}>{`Experience`}</div>) : (<>Experience</>)}
              </div>
            <div 
              style={{ 
                fontSize: scrollPosition == 3 ? '30px' : '22px',
                textAlign: 'center',
                opacity: scrollPosition < 5 ? 1 : 0, 
                height: scrollPosition < 5 ? '22px' : '0px', 
                transition: 'opacity 0.5s ease-in-out, height 0.5s ease-in-out, font-size 0.5s ease-in-out',
                position: 'relative',
                cursor: 'pointer',
              }}
              className={`${nav4Class}`}
              onClick={() => handleClick(5)}
              onMouseEnter={() => setOption4Hovered(true)}
              onMouseLeave={() => setOption4Hovered(false)}
            >
                {option4Hovered ? (<div style={{textDecoration: 'underline'}}>{`Contact`}</div>) : (<>Contact</>)}
              </div>
            </div>
                    )}
                  </div>

                  <div
                    style={{
                      color: '#333333',
                      position: 'fixed',
                      top: '0',
                      left: '0',
                      width: '100%',
                      padding: '10px',
                      textAlign: 'center',
                      zIndex: 1000,
                    }}
                  >
                    {!isSmallScreen && (
                        <div style={{ display: 'flex', justifyContent: 'center',
                          flexDirection: 'column',
                        alignItems: 'center', width: '100vw', 
                        gap: scrollPosition == 3 ? '40px' : '5px',
                        transition: 'gap 0.5s ease-in-out', }}>


            <div
              style={{
                fontSize: scrollPosition == 3 ? '30px' : '22px',
                textAlign: 'center',
                opacity: scrollPosition >= 2 ? 1 : 0,
                height: scrollPosition >= 2 ? '22px' : '0px',
                transition: 'opacity 0.5s ease-in-out, height 0.5s ease-in-out, font-size 0.5s ease-in-out',
                position: 'relative',
                cursor: 'pointer',
              }}
              onClick={() => handleClick(1)}
              onMouseEnter={() => setOption1Hovered(true)}
              onMouseLeave={() => setOption1Hovered(false)}
            >
                {option1Hovered ? (<div style={{textDecoration: 'underline'}}>{`Gavin D. Milligan`}</div>) : (<>Gavin D. Milligan</>)}
              </div>
                <div style={{ fontSize: scrollPosition == 3 ? '30px' : '22px',  textAlign: 'center',
                  opacity: scrollPosition >=3 ? 1 : 0, 
                  height: scrollPosition  >=3 ? '22px' : '0px', 
                  transition: 'opacity 0.5s ease-in-out, height 0.5s ease-in-out, font-size 0.5s ease-in-out',
                  position: 'relative',
                  cursor: 'pointer',
                }}
                onClick={() => handleClick(2)}
                onMouseEnter={() => setOption2Hovered(true)}
                onMouseLeave={() => setOption2Hovered(false)}
              >
                  {option2Hovered ? (<div style={{textDecoration: 'underline'}}>{`Skills`}</div>) : (<>Skills</>)}
                </div>
                <div style={{ fontSize: '22px',  textAlign: 'center',
                  opacity: scrollPosition >=4 ? 1 : 0, 
                  height: scrollPosition  >=4 ? '22px' : '0px', 
                  transition: 'opacity 0.5s ease-in-out, height 0.5s ease-in-out',
                  position: 'relative',
                  cursor: 'pointer',
                }}
                onClick={() => handleClick(3)}
                className="hover-effect"
              >
                Education
              </div>
                <div style={{ fontSize: '22px',  textAlign: 'center',
                  opacity: scrollPosition >=5 ? 1 : 0, 
                  height: scrollPosition  >=5 ? '22px' : '0px', 
                  transition: 'opacity 0.5s ease-in-out, height 0.5s ease-in-out',
                  position: 'relative',
                  cursor: 'pointer',
                }}
                onClick={() => handleClick(4)}
                className="hover-effect"
              >
                Experience
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

export default Navigation;